import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteManyApi, getApi } from "services/api";

const initialState = {
  property: [],
  selectedPropertiesId: [],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));

export const fetchPropertiesAsync = createAsyncThunk(
  "property/fetchProperties",
  async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/property/"
          : `api/property/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed propertys");
    }
  }
);



export const deletePropertyAsync = createAsyncThunk(
  "property/deleteProperty",
  async (selectedPropertiesId, { getState, rejectWithValue }) => {
    const { property } = getState();
    
    try {
      console.log(property.selectedPropertiesId,"this is selected propertys id s inside of the delete property async")
      await deleteManyApi("api/property/deleteMany",  property.selectedPropertiesId );
      return property.selectedPropertiesId;
    } catch (error) {
      return rejectWithValue("Failed to delete propertys");
    }
  }
);


const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setPropertyIds: (state, action) => {
      console.log(action.payload,"this is action payload")
      state.selectedPropertiesId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertiesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPropertiesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.property = action.payload;
      })
      .addCase(fetchPropertiesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  //     .addCase(deletePropertyAsync.pending, (state) => {
  //       console.log(state,"this is loading state")
  //       state.status = "loading";
  //     })
  //     .addCase(deletePropertyAsync.fulfilled, (state, action) => {
  //       state.status = "succeeded";
  //      console.log(action.payload,"payloads")
  //       // Remove the deleted propertys from the state
  //       state.property = state.property.filter(

  //   (property) =>  !action.payload.includes(property._id.toString())
  // );
  //       state.selectedPropertiesId = []; // Reset selection after deletion
  //       console.log(state.property,"this is succesed state")
  //     })
  //     .addCase(deletePropertyAsync.rejected, (state, action) => {
  //       state.status = "failed";
  //       console.log(state,"this is failed state")
  //       state.error = action.payload;
  //     });
  },
});

export const selectProperty = (state) => state?.property?.property || [];
export const selectStatus = (state) => state.property.status;
export const selectError = (state) => state.property.error;
export const getSelectedpropertiesId = (state) => state.property?.selectedPropertiesId;
export const { setPropertyIds  } = propertySlice.actions;

export default propertySlice.reducer;
