import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "services/api";

const initialState = {
  revenueReports: [],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));


export const fetchRevenueReportsAsync =  createAsyncThunk(
  "revenueReport/fetchProcessedDeals",
  async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/revenues/"
          : `api/revenues/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed deals");
    }
  }
);
const revenueReportSlice = createSlice({
  name: "revenueReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenueReportsAsync.pending, (state) => {
          state.status = "loading";
      })
      .addCase(fetchRevenueReportsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.revenueReports = action.payload;
      })
      .addCase(fetchRevenueReportsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectRevenueReports = (state) =>
  state.revenueReports.revenueReports||[];
export const selectStatus = (state) => state.revenueReports.status;
export const selectError = (state) => state.revenueReports.error;

export default revenueReportSlice.reducer;
