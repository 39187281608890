import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postApi } from "services/api";
import { deleteManyApi, getApi } from "services/api";

const initialState = {
  quotations: [],
  selectedQuotationsId: [],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));

export const fetchQuotationsAsync = createAsyncThunk(
  "quotations/fetchQuotations",
  async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/quotation/"
          : `api/quotation/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed quotations");
    }
  }
);
export const addQuotationAsync = createAsyncThunk(
  "quotations/addQuotation",
  async (quotationData, { rejectWithValue }) => {
    try {
      const response = await postApi("api/quotation/add", quotationData);
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to add quotation");
    }
  }
);

export const deleteQuotationAsync = createAsyncThunk(
  "quotations/deleteQuotation",
  async (selectedQuotationsId, { getState, rejectWithValue }) => {
    const { quotations } = getState();

    try {
      console.log(
        quotations.selectedQuotationsId,
        "this is selected quotations id s inside of the delete quotation async"
      );
      await deleteManyApi(
        "api/quotation/deleteMany",
        quotations.selectedQuotationsId
      );
      return quotations.selectedQuotationsId;
    } catch (error) {
      return rejectWithValue("Failed to delete quotations");
    }
  }
);

const quotationsSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    setQuotationIds: (state, action) => {
      state.selectedQuotationsId = action.payload;
    },
    addQuotation: (state, action) => {
      state.quotations.push(action.payload); // Mutation to add a new quotation
    },
    deleteQuotation: (state, action) => {
      state.quotations = state.quotations.filter(
        (quotation) => !action.payload.includes(quotation._id.toString())
      );
      state.selectedQuotationsId = []; // Reset selection after deletion
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotationsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuotationsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quotations = action.payload;
      })
      .addCase(fetchQuotationsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addQuotationAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        quotationsSlice.caseReducers.addQuotation(state, action);
      })
      .addCase(deleteQuotationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteQuotationAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        quotationsSlice.caseReducers.deleteQuotation(state, action);
      })
      .addCase(deleteQuotationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectQuotations = (state) => state?.quotations?.quotations || [];
export const selectStatus = (state) => state?.quotations?.status;
export const selectError = (state) => state?.quotations?.error;
export const getSelectedQuotationsId = (state) =>
  state?.quotations?.selectedQuotationsId;
export const { setQuotationIds, addQuotation, deleteQuotation } =
  quotationsSlice.actions;

export default quotationsSlice.reducer;
