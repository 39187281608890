import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteManyApi } from "services/api";
import { deleteApi } from "services/api";
import { postApi } from "services/api";
import { getApi } from "services/api";
import { putApi } from "services/api";

const initialState = {
  leads: [],
  selectedLeadsId: [],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));


export const fetchLeadsAsync =  createAsyncThunk(
  "leads/fetchLeads",
  async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/lead/"
          : `api/lead/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed deals");
    }
  }
);

export const addLeadAsync = createAsyncThunk(
  "leads/addLead",
  async (leadData, { rejectWithValue }) => {
    try {
      const response = await postApi("api/lead/add", leadData);
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to add lead");
    }
  }
);

export const deleteLeadAsync = createAsyncThunk(
  "leads/deleteLead",
  async (_, {  getState,rejectWithValue }) => {
    console.log("this is leads")
    const { leads } = getState();
    try {

      console.log(leads,"this is leads")

      await deleteManyApi(`api/lead/deleteMany, leads.selectedLeadsId `);

      return leads.selectedLeadsId; // Return the ID to remove it from the state
    } catch (error) {
      return rejectWithValue("Failed to delete lead");
    }
  }
);

export const assignLeadsToAgentAsync = createAsyncThunk(
  "leads/assignLeadsToAgent",
  async ({ leadIds, agentId }, { rejectWithValue }) => {
    try {
      const response = await putApi("api/lead/assign", { leadIds, agentId });
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to assign leads to agent");
    }
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setLeadsId: (state, action) => {
      state.selectedLeadsId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadsAsync.pending, (state) => {
          state.status = "loading";
      })
      .addCase(fetchLeadsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.leads = action.payload;
      })
      .addCase(fetchLeadsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      }) .addCase(deleteLeadAsync.pending, (state) => {
        console.log(state, "this is loading state")
        state.status = "loading";
      })
      .addCase(deleteLeadAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log(action.payload, "payloads")
        // Remove the deleted leads from the state
        state.leads = state.leads.filter(
          (lead) => !action.payload.includes(lead._id.toString())
        );
        state.selectedLeadsId = []; // Reset selection after deletion
        console.log(state.leads, "this is succeeded state")
      })
      .addCase(deleteLeadAsync.rejected, (state, action) => {
        state.status = "failed";
        console.log(state, "this is failed state")
        state.error = action.payload;
      })
      .addCase(assignLeadsToAgentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assignLeadsToAgentAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the assigned leads in the state
        state.leads = state?.leads?.map(lead => 
          action.payload?.leads?.includes(lead?._id.toString())
            ? { ...lead, assignedTo: action.payload.agentId }
            : lead
        );
        state.selectedLeadsId = []; // Reset selection after assignment
      })
      .addCase(assignLeadsToAgentAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectLeads = (state) =>
  state.leads.leads||[];
export const selectStatus = (state) => state.leads.status;
export const selectError = (state) => state.leads.error;
export const getSelectedLeadsId = (state) => state.leads?.selectedLeadsId;
export const { setLeadsId } = leadsSlice.actions;


export default leadsSlice.reducer;
