import revenueReportSlice from 'features/revenue-report/revenueReportSlice';
import editModalReducer from '../slices/editModalSlice/editModalSlice';
import { configureStore } from '@reduxjs/toolkit';
import invoiceSlice from 'features/invoices/invoiceSlice';
import dealSlice from 'features/deals/dealSlice';
import propertySlice from 'features/properties/propertySlice'
import quotationSlice from 'features/quotation/quotationSlice';
import leadsSlice from 'features/Leads/leadSlice';
import contactsSlice from 'features/contacts/contactSlice';
const store = configureStore({
    reducer: {
        editModal: editModalReducer,
        // Add other reducers here if needed
        revenueReports: revenueReportSlice,
        invoices: invoiceSlice,
        deals: dealSlice,
        property: propertySlice,
        quotations:quotationSlice,
        leads: leadsSlice,
        contacts:contactsSlice
        
    },
});

export default store;