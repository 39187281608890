export const constant = {
    baseUrl: process.env.REACT_APP_BASE_URL
}

export const authorities = [
    { id: 1, label: "Add User" },
    { id: 2, label: "View/Edit User" },
    { id: 3, label: "Set User Authority" },
    { id: 4, label: "Add Contact" },
    { id: 5, label: "View/Edit Contact" },
    { id: 6, label: "Add Properties" },
    { id: 7, label: "View/Edit Properties" },
    { id: 8, label: "Add Deals" },
    { id: 9, label: "View/Edit Deals" },
    { id: 10, label: "Add Leads" },
    { id: 11, label: "View/Edit Leads" },
    { id: 12, label: "Revenue Report" },
    { id: 13, label: "Reminder" },
    { id: 14, label: "View Self Contract Agreement" },
    { id: 15, label: "Add/Edit Location" },
    { id: 16, label: "Add/Edit Sub Location" },
    { id: 17, label: "Add/Edit Project" },
    { id: 18, label: "Show All Revenue" },
    { id: 19, label: "Show All Deals" },
    { id: 20, label: "View All Contract Agreement" },
    { id: 21, label: "Add Certificate" },
    { id: 22, label: "View Property Owner Detail" },
  ];
  
 export const locations = [
    { id: 0, label: "Airport Road" },
    { id: 1, label: "Al Bateen" },
    { id: 2, label: "Al Khalidiya" },
    { id: 3, label: "Al Muneera" },
    { id: 4, label: "Al Nahyan Camp" },
    { id: 5, label: "Al Nahyan" },
    { id: 6, label: "Al Raha" },
    { id: 7, label: "Al Raha Gardens" },
    { id: 8, label: "Al Reef Villas" },
    { id: 9, label: "Al Reem Island" },
    { id: 10, label: "Al Samha" },
    { id: 11, label: "Bain Al Jessrain" },
    { id: 12, label: "Between Two Bridges" },
    { id: 13, label: "Corniche" },
    { id: 14, label: "Corniche Area" },
    { id: 15, label: "Corniche Road" },
    { id: 16, label: "Al Bandar" },
    { id: 17, label: "Electra Street" },
    { id: 18, label: "Hamdan Street" },
    { id: 19, label: "Hydra Village" },
    { id: 20, label: "Khalifa Street" },
    { id: 21, label: "Mohamed Bin Zayed City" },
    { id: 22, label: "Saadiyat Island" },
    { id: 23, label: "Shakhbout City" },
    { id: 24, label: "Yas Island" },
    { id: 25, label: "Al Raha Beach" },
    { id: 26, label: "Al Raha Golf Gardens" },
    { id: 27, label: "Al Rahba" },
    { id: 28, label: "Al Reef Downtown" },
    { id: 29, label: "Abu Dhabi City" },
    { id: 30, label: "Khalifa City" },
    { id: 31, label: "Al Qurm" },
    { id: 32, label: "Abu Dhabi City Gate" },
    { id: 33, label: "Masdar City" },
    { id: 34, label: "Al Maryah Island" },
    { id: 35, label: "Khalifa City" },
    { id: 36, label: "Tourist Club Area" },
    { id: 37, label: "Dubai" },
    { id: 38, label: "Al Karamah" },
    { id: 39, label: "Al Ghadeer" },
    { id: 40, label: "Reem Island" },
    { id: 41, label: "Mag 5" },
    { id: 42, label: "Khalidiyah" },
    { id: 43, label: "Saadiyat Island" },
    { id: 44, label: "Saadiyat Island" },
    { id: 45, label: "Noida" },
    { id: 46, label: "Sharjah" },
    { id: 47, label: "Abu Dhabi" },
    { id: 48, label: "saadiyat island" },
    { id: 49, label: "Corniche Residence" },
    { id: 50, label: "Dubai" },
    { id: 51, label: "Gurugram" },
    { id: 52, label: "Dubai" },
    { id: 53, label: "Al Salam Street" },
    { id: 54, label: "Corniche Residence" },
    { id: 55, label: "Yas Acre" },
    { id: 56, label: "Abu Dhabi Gate" },
    { id: 57, label: "Al Maqtaa Village" },
  ];