import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "services/api";

const initialState = {
  invoices: [],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));


export const fetchInvoicesAsync =  createAsyncThunk(
  "invoices/fetchInvoices",
  async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/invoices/"
          : `api/invoices/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed deals");
    }
  }
);
const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoicesAsync.pending, (state) => {
          state.status = "loading";
      })
      .addCase(fetchInvoicesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoices = action.payload;
      })
      .addCase(fetchInvoicesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectInvoice = (state) =>
  state.invoices.invoices||[];
export const selectStatus = (state) => state.invoices.status;
export const selectError = (state) => state.invoices.error;

export default invoicesSlice.reducer;
